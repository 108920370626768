import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import { sync } from 'vuex-router-sync'
import store from './store/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMasonry from 'vue-masonry-css'
import VueTransmit from "vue-transmit"
const unsync = sync(store, router)
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import abWidget from '@/components/general/widget'
import 'vue-croppa/dist/vue-croppa.css'
import Croppa from 'vue-croppa'
import * as GmapVue from 'gmap-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import JwPagination from 'jw-vue-pagination'
import VueSpinners from 'vue-spinners'
import VueQrcodeReader from "vue-qrcode-reader"
const _ = require('lodash');

Vue.component('abWidget', abWidget);
Vue.component('jw-pagination', JwPagination);

Vue.use(VueQrcodeReader);
Vue.use(VueSpinners);
Vue.use(VueMasonry);
Vue.use(Viewer);
Vue.use(Croppa);
Vue.use(VueAwesomeSwiper);
Vue.use(require('vue-moment'));
Vue.use(VueAxios, axios);
Vue.use(VueTransmit);


Vue.use(GmapVue, {
	load: {
		key: 'AIzaSyDdtIP6cg5VzAZwZWYxkC088HXKtBA1sJo',
		libraries: 'places'
	},

	//// If you intend to programmatically custom event listener code
	//// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
	//// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
	//// you might need to turn this on.
	// autobindAllEvents: false,

	//// If you want to manually install components, e.g.
	//// import {GmapMarker} from 'gmap-vue/src/components/marker'
	//// Vue.component('GmapMarker', GmapMarker)
	//// then set installComponents to 'false'.
	//// If you want to automatically install all the components this property must be set to 'true':
	installComponents: true
})

import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

const firebaseConfig = {
apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
appId: process.env.VUE_APP_FIREBASE_APP_ID,
measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
Vue.prototype.$analytics = firebase.analytics();
Vue.config.productionTip = false

new Vue({
	router,
	store,
	el: '#app',
	render: h => h(App),
})
