import router from '@/router/router'

export default {
    state: { 
        modal: {
            open: false,
            subTitle: '',
            heading: ''
        }
    },
    mutations: {
        openOnboardingModal(state) {
            state.modal.open = true;
        },
        closeOnboardingModal(state) {
            state.modal.open = false;
        },
        setOnboardingModalText(state, object) {
            state.modal.subTitle = object.sub;
            state.modal.heading = object.head;
        },
    },
    actions: {
        confirmSubscriptionModal({commit, dispatch}) {
            return new Promise(function(resolve, reject) {
                commit('openOnboardingModal');
                commit('setOnboardingModalText', {sub: 'HANG TIGHT FOR JUST A MINUTE', head: 'We’re creating your Swarm account'});
                setTimeout(function() {
                    router.push({name: 'home'});
                }, 1500);
                setTimeout(function() {
                    commit('setOnboardingModalText', {sub: 'ALL DONE!', head: 'Time to get started'});
                }, 2750);
                setTimeout(function() {
                    dispatch('authUserSignIn');
                    commit('closeOnboardingModal');
                }, 3800);
            });
        },
    },
    getters: {
        onboardingModal(state) {
            return state.modal;
        }
    }
}