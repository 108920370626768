import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'
import routes from './routes'
Vue.use(Router)

const router = new Router({
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (to.path != '/' && !to.meta.allowAnonymous && !store.state.user.auth.loggedIn) {
    store.dispatch("authSignIn");
    next({
      path: '/',
    })
  }
  else {
    next()
  }
})

export default router;
