import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import subscription from './modules/subscription'
import onboarding from './modules/onboarding'
import createPersistedState from "vuex-persistedstate";
import Cookies from 'js-cookie';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    subscription,
    onboarding
  },
  state: {
    
  },
  mutations: {

  },
  actions: {

  },
  plugins: [createPersistedState()],
})
