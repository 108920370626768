<template>
    <div class="auth-modal auth-sign-in-modal">
        <button @click="close" class="icon-btn close"><i class="material-icons">clear</i></button>
        <div class="form">
            <header>
            </header>
            <div class="form-content">
                <h1>Sign In </h1>
              <form @submit.prevent="onSubmit">
                <input type="username" placeholder="username" v-model="username">
                <input type="password" placeholder="password" autocomplete="on" v-model="password">
                <p class="error" v-if="error">{{ error }}</p>
                <section class="forgot">
                    <span @click="goToPassReset">forgot password</span>
                </section>
                <button type="submit" class="btn-block-primary">Log In</button>
                <footer class="form-content-foot">
                    Don't have an account? Sign up <a @click="goToSignUp">here</a>
                </footer>
              </form>
            </div>
            <footer class="foot">
            </footer>
        </div>
        <div class="media">
            <img src="@/assets/modal_bg.jpg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'auth--confirm-modal',
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('authUserSignIn',{
        username : this.username,
        password : this.password
      });
    },
    goToSignUp() {
            this.$store.dispatch('authSignUp');
        },
        goToPassReset() {
            this.$router.push({name: 'password reset request'});
            this.close();
        },
        close() {
            this.$store.dispatch('authModalClose');
        }
    },
  computed: {
    error() {
      if(this.$store.getters.userAuth.error)
      return 'Login error, please try again.';
    }
  },
  mounted() {
    this.$store.getters.userAuth.error = null;
  }
}
</script>

<style lang="scss" scoped>
.form {
    .form-content {
        margin-top: 55px;
        h1 {
            text-align: center;
        }
    }
    header {
        text-align: center;
        img {
            max-width: 56px;
            height: auto;
            display: inline;
        }
    }
}
.error {
  color: red;
}
.form-content-foot {}

@media(max-width: 1000px) {
    .media {
        display: none;
    }
}

</style>
