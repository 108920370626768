import router from '../../router/router'
import user from "../../views/user/user";

const baseUrl = 'https://us-central1-swarm-646f9.cloudfunctions.net/hive';

export default {
    state: {
        devices: null,
        messages: null,
        userInfo: null,
        userInfoError: '',
        auth: {
            open: false,
            type: null,
            loggedIn: false,
            token: "",
            error: '',
            changePasswordError: ''
        },
        selectedLocation:null,
        selectedDevice:null,
        activatedDevice:null,
        deActivatedDevice:null,
        isLoading: false
    },
    mutations: {
        setIsLoading(state, payload) {
            state.isLoading = payload;
        },
        setDeactivatedDevice(state, payload) {
            state.deActivatedDevice = payload;
        },
        setActivatedDevice(state, payload) {
            state.activatedDevice = payload;
        },
        setSelectedDevice(state, payload) {
            state.selectedDevice = payload;
        },
        setUserDevices(state, payload) {
            state.devices = payload;
            },
        setUserMessages(state, payload) {
            state.messages = payload;
        },
        setUserInfo(state, payload) {
            state.userInfo = payload;
        },
        setSelectedLocation(state, payload) {
            state.selectedLocation = payload;
        },
        setAuthToken(state, payload) {
            state.auth.token = payload;
        },
       setAuthModalState(state, bool) {
           state.auth.open = bool;
       },
       setLoggedIn(state, bool) {
           state.auth.loggedIn = bool;
       },
        setAuthModalType(state, type) {
            state.auth.type = type;
        },
        setAuthModalError(state, payload) {
            state.auth.error = payload;
        },
        setChangePasswordError(state, payload) {
            state.auth.changePasswordError = payload;
        },
        setUserInfoError(state, payload) {
            state.userInfoError = payload;
        }
    },
    actions: {
        forceLogout({ commit, state }, params) {
            commit('setLoggedIn', false),
                commit('setAuthToken', ''),
                commit('setAuthModalType', null),
                commit('setAuthModalState', false),
                commit('setUserInfo',null),
                commit('setUserDevices',null),
                commit('setUserMessages',null),
                commit('setSelectedLocation',null),
                commit('setSelectedDevice',null)
        },
        testAction({commit, state, getters},payload) {
            return new Promise(function(resolve, reject) {
                console.log('testAction')
            });
        },
        mapSelectedLocation({commit, state, getters},payload) {
            return new Promise(function(resolve, reject) {
                commit("setSelectedLocation",payload)
            });
        },
        authUserSignUp({commit, state, getters}) {
            return new Promise(function(resolve, reject) {
                // code to sign user up
            });
        },
        authUserSignIn({commit, state, getters, dispatch},payload) {
            return new Promise(function(resolve, reject) {
                var myHeaders = new Headers();
                const requestOptions = {
                    method: "POST",
                    cache: "no-cache",
                    mode: "cors"
                };
                var url = new URL(baseUrl + '/login');
                url.search = new URLSearchParams(payload);
                fetch(url,requestOptions)
                    .then(
                function(response) {
                            if (!response.ok) {
                                if(response.status == 401) {
                                    dispatch('forceLogout')
                                }
                                    throw new Error("HTTP status " + response.status);
                            }
                    return response.json();
                        }
                    )
                    .then(json => {
                        resolve(
                            commit('setLoggedIn', true),
                            commit('setAuthToken', 'Bearer ' + json.token),
                            commit('setAuthModalType', null),
                            commit('setAuthModalState', false),
                        );
                        dispatch('fetchAuthUser');
                    })
                    .catch(error => {
                        commit('setAuthModalError', error)
                    });
            });
        },
        authUserLogOut({commit, state, getters,dispatch}) {
            return new Promise(function(resolve, reject) {
                var myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json; charset=utf-8');
                myHeaders.append('Authorization', 'Bearer ' + state.auth.token);
                const requestOptions = {
                    method: "GET",
                    cache: "default",
                    mode: "cors",
                    headers: myHeaders
                };
                fetch(baseUrl + '/logout', requestOptions)
                    .then(response => response.json())  // convert to json
                    .then(json => {
                        resolve(
                            dispatch('forceLogout')
                        );
                        router.push({name: 'home'});
                    })    //print data to console
                    .catch(err => console.log('Request Failed', err)); // Catch errors
             });
        },
        fetchAuthUser({commit, state, getters}) {
            return new Promise(function(resolve, reject) {
                var myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json; charset=utf-8');
                myHeaders.append('Authorization', state.auth.token);
                const requestOptions = {
                    method: "GET",
                    cache: "default",
                    mode: "cors",
                    headers: myHeaders
                };
                fetch(baseUrl + '/api/v1/users/me/info', requestOptions)
                    .then(response => response.json())  // convert to json
                    .then(json => {
                        resolve(
                            commit('setUserInfo',json),
                    );
                        router.push({name: 'user dashboard', params: {id: state.userInfo.userId}})

                    })    //print data to console
                    .catch(err => console.log('Request Failed', err)); // Catch errors
            });
        },
        updateAuthUser({commit, state, getters, dispatch},payload) {
            return new Promise(function(resolve, reject) {
                var myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json; charset=utf-8');
                myHeaders.append('Authorization', state.auth.token);
                const requestOptions = {
                    method: "POST",
                    cache: "default",
                    mode: "cors",
                    body: payload,
                    headers: myHeaders
                };
                fetch(baseUrl + '/api/v1/users/me/info',requestOptions)
                    .then(
                        function(response) {
                            if (!response.ok) {
                                if(response.status == 401) {
                                    dispatch('forceLogout')
                                }
                                commit('setUserInfoError', response.json());
                                throw new Error("HTTP status " + response.status);
                            }
                            return response.json();
                        }
                    )
                    .then(json => {
                        resolve(
                            commit('setUserInfoError', null),
                        commit('setUserInfo',json),
                        );
                    })    //print data to console
                    .catch(err => console.log('Request Failed', err)); // Catch errors
            });
        },

        changeAuthUserPassword({commit, state, getters, dispatch},payload) {
            return new Promise(function(resolve, reject) {
                var myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json; charset=utf-8');
                myHeaders.append('Authorization', state.auth.token);
                const requestOptions = {
                    method: "POST",
                    cache: "default",
                    mode: "cors",
                    body: payload,
                    headers: myHeaders
                };
                fetch(baseUrl + '/api/v1/users/me/password',requestOptions)
                    .then(
                        function(response) {
                            if (!response.ok) {
                                if(response.status == 401) {
                                    dispatch('forceLogout')
                                }
                                commit('setChangePasswordError', response.json());
                                throw new Error("HTTP status " + response.status);
                            }
                            return response.json();
                        }
                    )
                    .then(json => {
                        resolve(
                        commit('setChangePasswordError', null)
                        );
                        console.log(json);

                    })    //print data to console
                    .catch(err =>
                        console.log(err)

            ); // Catch errors
            });
        },
        fetchDevices({commit, state, getters}) {
            return new Promise(function(resolve, reject) {
                var myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json; charset=utf-8');
                myHeaders.append('Authorization', state.auth.token);
                const requestOptions = {
                    method: "GET",
                    cache: "default",
                    mode: "cors",
                    headers: myHeaders
                };
                fetch(baseUrl + '/api/v1/devices', requestOptions)
                    .then(response => response.json())  // convert to json
                    .then(json => {
                        resolve(
                            commit('setUserDevices',json),
                        );
                    })    //print data to console
                    .catch(err => console.log('Request Failed', err)); // Catch errors
            });
        },
        activateDevice({commit, state, getters, dispatch},payload) {
            return new Promise(function(resolve, reject) {
                var myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json; charset=utf-8');
                myHeaders.append('Authorization', state.auth.token);
                const requestOptions = {
                    method: "POST",
                    cache: "default",
                    mode: "cors",
                    headers: myHeaders
                };
                var url = new URL(baseUrl + '/api/v1/devices/activate');
                url.search = new URLSearchParams(payload);
                fetch(url,requestOptions)
                    .then(
                        function(response) {
                            if (!response.ok) {
                                if(response.status == 401) {
                                    dispatch('forceLogout')
                                }
                                throw new Error("HTTP status " + response.status);
                            }
                            return response.json();
                        }
                    )
                    .then(json => {
                        resolve(
                            commit('setActivatedDevice', json)
                        );
                        console.log(json);

                    })    //print data to console
                    .catch(err =>
                        console.log(err)
                    ); // Catch errors
            });
        },
        deActivateDevice({commit, state, getters, dispatch},payload) {
            return new Promise(function(resolve, reject) {
                var myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json; charset=utf-8');
                myHeaders.append('Authorization', state.auth.token);
                const requestOptions = {
                    method: "POST",
                    cache: "default",
                    mode: "cors",
                    headers: myHeaders
                };
                var url = new URL(baseUrl + '/api/v1/devices/deactivate');
                url.search = new URLSearchParams(payload);
                fetch(url,requestOptions)
                    .then(
                        function(response) {
                            if (!response.ok) {
                                if(response.status == 401) {
                                    dispatch('forceLogout')
                                }
                                throw new Error("HTTP status " + response.status);
                            }
                            return response.json();
                        }
                    )
                    .then(json => {
                        resolve(
                            commit('setDeactivatedDevice', json)
                        );
                        console.log(json);

                    })    //print data to console
                    .catch(err =>
                        console.log(err)
                    ); // Catch errors
            });
        },

        fetchMessages({commit, state, getters},payload) {
            commit('setIsLoading',true);
            return new Promise(function(resolve, reject) {
                var myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json; charset=utf-8');
                myHeaders.append('Authorization', state.auth.token);
                const requestOptions = {
                    method: "GET",
                    cache: "default",
                    mode: "cors",
                    headers: myHeaders
                };
                var url = new URL(baseUrl + '/api/v1/messages');
                url.search = new URLSearchParams(payload);
                fetch(url, requestOptions)
                    .then(response => response.json())  // convert to json
                    .then(json => {
                        resolve(
                            commit('setUserMessages',json),
                            commit('setIsLoading',false),
                        );
                    })    //print data to console
                    .catch(err => console.log('Request Failed', err));// Catch errors
            });
        },
        sendMessage({commit, state, getters, dispatch},payload) {
            console.log(payload)

            return new Promise(function(resolve, reject) {
                var myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json; charset=utf-8');
                myHeaders.append('Authorization', state.auth.token);
                const requestOptions = {
                    method: "POST",
                    cache: "default",
                    mode: "cors",
                    body: payload,
                    headers: myHeaders
                };
                var url = new URL(baseUrl + '/api/v1/messages');
                fetch(url,requestOptions)
                    .then(
                        function(response) {
                            if (!response.ok) {
                                if(response.status == 401) {
                                    dispatch('forceLogout')
                                }
                                console.log(response)
                                throw new Error("HTTP status " + response.status);
                            }
                            return response.json();
                        }
                    )
                    .then(json => {
                        resolve(
                        );
                        console.log(json);

                    })    //print data to console
                    .catch(err =>
                        console.log(err)
                    ); // Catch errors
            });
        },

        // Use these controls to display the proper sign in / up / confirm modals anywhere throughout the site
        authSignUp({commit, state, getters}) {
            return new Promise(function(resolve, reject) {
                resolve(
                    commit('setAuthModalState', true),
                    commit('setAuthModalType', 'signUp')
                );
            });
        },
        authSignIn({commit, state, getters}) {
            return new Promise(function(resolve, reject) {
                resolve(
                    commit('setAuthModalState', true),
                    commit('setAuthModalType', 'signIn')
                );
            });
        },
        authConfirm({commit, state, getters}) {
            return new Promise(function(resolve, reject) {
                resolve(
                    commit('setAuthModalState', true),
                    commit('setAuthModalType', 'confirm')
                );
            });
        },
        authModalClose({commit, store, getters}) {
            return new Promise(function(resolve, reject) {
                resolve(
                    commit('setAuthModalState', false),
                    commit('setAuthModalType', null)
                );
            });
        }
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        },
        userInfo(state) {
            return state.userInfo ? state.userInfo : null;
        },
        userInfoError(state) {
            return state.userInfoError ? state.userInfoError : null;
        },
        userAuth(state) {
            return state.auth;
        },
        maplocation(state) {
            return state.selectedLocation;
        },
        selectedDevice(state) {
            return state.selectedDevice;
        },
        userDevices(state) {
            return state.devices;
        },
        userMessages(state) {
            return state.messages;
        }
    },
}