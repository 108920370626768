<template>
  <div class="home">
      <home-hero
        head="Swarm Technologies"
        sub="Global, affordable IoT connectivity">
      </home-hero>
      <div class="wrap">
      </div>
  </div>
</template>
<script>
import homeHero from "@/components/home/hero";
export default {
  name: "home",
  components: {
    homeHero
  }
};
</script>
<style lang="scss" scoped>
.wrap {
    margin: 0 auto;
    max-width: 1230px;
}
.home {
  background-color: #F5F7F7;
}
</style>

