import Home from '../views/Home.vue'
import store from '../store/store'
export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      hasNav: true,
      allowAnonymous: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      hasNav: true,
      allowAnonymous: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/about.vue')
  },
  {
    path: '/user/:id',
    name: 'user',
    meta: {
      hasNav: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/user/user.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'user dashboard',
        meta: {
          hasNav: true,
          displayName: 'Dashboard',
        },
        component: () => import('../views/user/children/dashboard')
      },
      {
        path: 'map-display',
        name: 'user map-display',
        meta: {
          hasNav: true,
          displayName: 'Map',
        },
        component: () => import('../views/user/children/map-display')
      },
      {
        path: 'devices',
        name: 'user devices',
        redirect: { name: 'view user devices' },
        meta: {
          hasNav: true,
          displayName: 'Devices',
        },
        component: () => import('../views/user/children/devices'),
        children: [
          {
            path: '',
            name: 'view user devices',
            meta: {
              hasNav: true,
            },
            component: () => import('../views/user/children/devices/view')
          },
          {
            path: 'create',
            name: 'create devices',
            meta: {
              hasNav: true,
            },
            component: () => import('../views/user/children/devices/create')
          }
        ]
      },
      {
        path: 'messages',
        name: 'user messages',
        redirect: { name: 'view user messages' },
        meta: {
          hasNav: true,
          displayName: 'Messages',
        },
        component: () => import('../views/user/children/messages'),
        children: [
          {
            path: '',
            name: 'view user messages',
            meta: {
              hasNav: true,
            },
            component: () => import('../views/user/children/messages/view')
          },
          {
            path: 'create',
            name: 'create messages',
            meta: {
              hasNav: true,
            },
            component: () => import('../views/user/children/messages/create')
          }
        ]
      },
      {
        path: 'account-settings',
        name: 'user account settings',
        meta: {
          hasNav: true,
          displayName: 'account settings'
        },
        component: () => import('../views/user/children/account-settings')
      }
    ]
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    meta: {
      hasNav: true,
      allowAnonymous: true,
    },
    component: () => import('../views/onboarding/onboarding'),
    children: [
      {
        path: 'welcome',
        name: 'onboarding welcome',
        meta: {
          hasNav: true,
          allowAnonymous: true,
        },
        component: () => import('../views/onboarding/children/welcome')
      },
      {
        path: 'plan',
        name: 'onboarding plan',
        meta: {
          hasNav: true,
          allowAnonymous: true,
        },
        component: () => import('../views/onboarding/children/plan')
      },
      {
        path: 'payment',
        name: 'onboarding payment',
        meta: {
          hasNav: true,
          allowAnonymous: true,
        },
        component: () => import('../views/onboarding/children/payment')
      },
      {
        path: 'password-reset-request',
        name: 'password reset request',
        meta: {
          hasNav: true,
          allowAnonymous: true,
        },
        component: () => import('../views/onboarding/children/forgot-password-request')
      },
      {
        path: 'password-reset',
        name: 'password reset',
        meta: {
          hasNav: true,
          allowAnonymous: true,
        },
        component: () => import('../views/onboarding/children/forgot-password')
      },
      {
        path: 'password-reset check',
        name: 'password reset check',
        meta: {
          hasNav: true,
          allowAnonymous: true,
        },
        component: () => import('../views/onboarding/children/forgot-password-check')
      },
    ]
  },
  {
    path: '/terms-and-conditions',
    name: 'terms',
    component: () => import('../views/terms-and-conditions.vue'),
    meta: {
      hasNav: true,
      pageName: 'Terms & Conditions',
      allowAnonymous: true,
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/privacy.vue'),
    meta: {
      hasNav: true,
      pageName: 'Privacy Policy',
      allowAnonymous: true,
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/faq.vue'),
    meta: {
      hasNav: true,
      pageName: "FAQ's",
      allowAnonymous: true,
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue'),
    meta: {
      hasNav: true,
      pageName: 'Contact Us',
      allowAnonymous: true,
    },
  },
];

