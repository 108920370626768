<template>
  <div>
    <top-nav></top-nav>
    <router-view />
    <auth-modal />
    <subscription-modal />
    <onboarding-modal />
    <swarmFooter></swarmFooter>
  </div>
</template>

<script>
import topNav from "@/components/navigation/top-nav";
import authModal from '@/components/auth/auth-modal'
import subscriptionModal from '@/components/subscription/modal'
import onboardingModal from '@/components/onboarding/modal'
import swarmFooter from '@/components/footer'
import router from "./router/router";

export default {
  name: 'App',
  components: {
    topNav,
    router,
    authModal,
    subscriptionModal,
    onboardingModal,
    swarmFooter
  }
}
</script>

<style lang="scss">
@import "./styles/main";

#app {
  padding-bottom: 32px;
}
</style>
