<template>
    <aside class="user-sidebar">
        <div class="social">
            <div class="social-item">
                <div class="desc">
                    <i class="material-icons">satellite_alt</i>
                    <span>Selected Device</span>
                </div>
            </div>
          <div v-if="!currentDevice">
            <button @click="$router.push({name: 'user devices'})" class='btn-block-primary'>Select Device</button>
          </div>
          <div v-if="currentDevice">
          <div class="social-item">
            <div class="data">
              <span>Device Name: {{currentDevice.deviceName}}</span>
            </div>
          </div>
          <div class="social-item">
            <div class="data">
              <span>Device ID: {{currentDevice.deviceId}}</span>
            </div>
          </div>
          </div>

          <div class="social-item">
                <div class="desc">
                    <i class="material-icons">gps_fixed</i>
                    <span>Last Heard Location</span>
            </div>
            </div>
          <div v-if="currentLocation">
          <div class="social-item">
            <div class="data">
              <span>Date: {{dateString}}</span>
            </div>
          </div>
          <div class="social-item">
            <div class="data">
              <span>Latitude: {{currentLocation.lt}}</span>
            </div>
          </div>
          <div class="social-item">
            <div class="data">
              <span>Longitude: {{currentLocation.ln}}</span>
            </div>
          </div>
          <div class="social-item">
            <div class="data">
              <span>Last RSSI Value: {{currentLocation.r}}</span>
            </div>
          </div>
          <div class="social-item">
            <div class="data">
              <span>Altitude: {{currentLocation.s}}</span>
            </div>
          </div>
          </div>
          </div>
    </aside>
</template>

<script>

export default {
    name: 'user-sidebar',
    computed: {
        user() {
            return this.$store.getters.userInfo;
        },
        currentLocation() {
            return this.$store.getters.maplocation;
        },
      currentDevice() {
        return this.$store.getters.selectedDevice;
      },
      dateString() {
        const vm = this;
        return this.$moment(vm.currentLocation.hiveRxTime).format("MMM Do, YYYY HH:mm");
      }
    }
}
</script>

<style lang="scss">
    .user-sidebar {
        padding: 48px 24px;
        width: 332px;
        flex: 332px 0 0;
        box-shadow: inset -1px 0 0 0 #E8ECED;
        background: #fff;
        max-height: calc(100vh - 97px);
        overflow-y: auto;
        .user-meta {
            h1 {
                font-size: 24px;	
                font-weight: bold;
                letter-spacing: 0.17px;	
                line-height: 28px;
                margin-bottom:0;
            }
            .location {
                margin-top: 4px;
                  font-size: 12px;	
                  letter-spacing: 0.5px;	
                  line-height: 12px;
                  opacity: .8; 
                  display: flex;
                  align-items: center;
                  i {
                      opacity: .3;
                      font-size: 16px;
                      margin-right: 2px;
                  }          
            }
        }
        .social {
            border-top: 1px solid #E8EAEB;
            .social-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 18px 0;
                border-bottom: 1px solid #E8EAEB;
                > div {
                    display: flex;
                    align-items: center;
                }
                .desc {
                    font-size: 13px;	
                    font-weight: 500;	
                    letter-spacing: 1.08px;	
                    line-height: 18px;
                    i {
                        margin-right: 14px;
                    }
                }
                .data {
                    span {
                        font-size: 12px;
                        font-weight: bold;	
                        letter-spacing: 1px;
                        line-height: 14px;
                        margin-left: 40px;
                    }
                }
            }
        }
    }

</style>
